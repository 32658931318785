/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2022-11-29 10:41:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-08 14:24:13
 */
import {
  AddReg,
  AnalysisOfPrice,
  AnalysisOfPriceReg,
  AnalysisOfPriceRes,
  CatalogRes,
  ComputeReg,
  PriceComputeTable,
  ReplaceReg,
  UpdatePrice,
} from '@/model/competitiveAnalysis/analysisOfPrice/analysisOfPrice'
import { $axios } from '../config/axios'
class AnalysisOfPriceApi {
  //价格分析分页展示。
  analysisOfPriceApiList(data: AnalysisOfPriceReg): Promise<AnalysisOfPriceRes> {
    return $axios.post('/api/price/list', data)
  }
  // 根据id获取价格分析详细信息
  analysisOfPriceApiDetail(id: string): Promise<AnalysisOfPrice> {
    return $axios.post(`/api/price/detail?id=${id}`, {})
  }
  // 根据id集合批量导出
  analysisOfPriceApiExport(idList: string[]): Promise<Blob> {
    return $axios.post('/api/price/export', idList, { responseType: 'arraybuffer' })
  }
  // 根据id集合批量删除价格分析
  analysisOfPriceApiDelete(idList: string[]): Promise<AnalysisOfPriceRes> {
    return $axios.post('/api/price/delete', idList)
  }
  // 更新价格分析数据
  analysisOfPriceApiUpdate(data: UpdatePrice): Promise<AnalysisOfPriceRes> {
    return $axios.post('/api/price/update', data)
  }
  // 关注价格分析
  analysisOfPriceApiFollowAdd(id: string): Promise<AnalysisOfPriceRes> {
    return $axios.post(`/api/bcic/price/follow/add?id=${id}`, {})
  }
  // 计算表格数据
  analysisOfPriceApiCompute(data: ComputeReg): Promise<AnalysisOfPrice> {
    return $axios.post('/api/price/compute', data)
  }
  // 批量导入价格分析
  analysisOfPriceApiImport(data: FormData): Promise<AnalysisOfPriceRes> {
    return $axios.post('/api/price/import', data)
  }
  // 取消关注价格分析
  analysisOfPriceApiFollowCancel(id: string): Promise<AnalysisOfPriceRes> {
    return $axios.post(`/api/bcic/price/follow/cancel?id=${id}`, {})
  }
  // 生成价格分析数据
  analysisOfPriceApiAdd(data: AddReg): Promise<string> {
    return $axios.post('/api/price/add', data)
  }
  // 替换一条抽取数据
  analysisOfPriceApiReplace(data: ReplaceReg): Promise<PriceComputeTable> {
    return $axios.post('/api/price/replace', data)
  }
  // 重新抽取全部数据
  analysisOfPriceApiExtract(id: string): Promise<string> {
    return $axios.post('/api/price/extract?id=' + id, {})
  }
  // 获取目录信息列表
  analysisOfPriceApiCatalogList(data: unknown): Promise<CatalogRes> {
    return $axios.post('/common/v1/0/catalog/list', data)
  }
}
let analysisOfPriceApi
export default (() => {
  if (analysisOfPriceApi) return analysisOfPriceApi
  analysisOfPriceApi = new AnalysisOfPriceApi()
  return analysisOfPriceApi
})()
